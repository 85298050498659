const Report = () => {
    return(
    <section className="hero">
        <div className="hero-body">
            <div className={"box"}>
                This is to be built
            </div>
        </div>
    </section>
    )
}

export {Report}